// Header.jsx
import { useState } from 'react';
import ThemeToggle from './ThemeToggle';
import TextScramble from './TextScramble';

interface IconProps { 
  className: string;
}

function CopyIcon({ className }: IconProps) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1em"
      width="1em"
      className={className}
    >
      <path d="M20 2H10c-1.103 0-2 .897-2 2v4H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2v-4h4c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM4 20V10h10l.002 10H4zm16-6h-4v-4c0-1.103-.897-2-2-2h-4V4h10v10z" />
    </svg>
  );
}

function CheckIcon({ className }: IconProps) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1em"
      width="1em"
      className={className}
    >
      <path d="M9 16.2l-3.5-3.5L4 14l5 5 12-12-1.5-1.5L9 16.2z" />
    </svg>
  );
}

export default function Header() {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText('pip install thoughtbase').then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    }).catch((err) => {
      console.error('Failed to copy!', err);
    });
  };

  const phrases = [
    'Intelligence Begins with a Single Thought.',
    'Engineer Your Thoughts Today.',
  ];

  return (
    <header className="absolute top-4 md:top-6 w-full z-50 pb-4 md:pb-6 border-b [border-image:linear-gradient(to_right,transparent,theme(colors.teal.300/.4),transparent)1] dark:[border-image:linear-gradient(to_right,transparent,theme(colors.teal.300/.16),transparent)1] shadow-[0_1px_0_0_theme(colors.white/.2)] dark:shadow-none">
      <div className="px-4 sm:px-6">
        <div className="max-w-3xl mx-auto">
          <div className="relative flex items-center justify-between gap-x-2 h-12 bg-gradient-to-b from-white/90 to-white/70 dark:from-gray-700/80 dark:to-gray-700/70 rounded-lg px-3 shadow">
            {/* Border with dots in corners */}
            <div
              className="absolute -inset-1.5 bg-teal-500/15 dark:bg-gray-800/50 rounded-sm -z-10 before:absolute before:inset-y-0 before:left-0 before:w-[10px] before:bg-[length:10px_10px] before:[background-position:top_center,bottom_center] before:bg-no-repeat before:[background-image:radial-gradient(circle_at_center,theme(colors.teal.500/.56)_1px,transparent_1px),radial-gradient(circle_at_center,theme(colors.teal.500/.56)_1px,transparent_1px)] dark:before:[background-image:radial-gradient(circle_at_center,theme(colors.gray.600/.56)_1px,transparent_1px),radial-gradient(circle_at_center,theme(colors.gray.600/.56)_1px,transparent_1px)] after:absolute after:inset-y-0 after:right-0 after:w-[10px] after:bg-[length:10px_10px] after:[background-position:top_center,bottom_center] after:bg-no-repeat after:[background-image:radial-gradient(circle_at_center,theme(colors.teal.500/.56)_1px,transparent_1px),radial-gradient(circle_at_center,theme(colors.teal.500/.56)_1px,transparent_1px)] dark:after:[background-image:radial-gradient(circle_at_center,theme(colors.gray.600/.56)_1px,transparent_1px),radial-gradient(circle_at_center,theme(colors.gray.600/.56)_1px,transparent_1px)]"
              aria-hidden="true"
            />
            {/* Site branding */}
            <div
              className="flex-1 text-gray-700 dark:text-gray-400 text-md inline-flex items-center"
              style={{ fontFamily: 'Roboto Mono, monospace' }}
            >
              {/* <TextScramble phrases={phrases} /> */}
              <span className='select-none pr-2'>{'>'}</span>pip install thoughtbase
              {/* Copy logo and functionality */}
              <button
                className="ml-2 text-gray-400 dark:text-gray-500 hover:text-gray-500 dark:hover:text-gray-400 flex items-center relative"
                onClick={handleCopy}
                aria-label="Copy command"
              >
                {copied ? (
                  <CheckIcon className="w-5 h-5 text-green-500" />
                ) : (
                  <CopyIcon className="w-5 h-5" />
                )}
                {/* Tooltip or feedback message */}
                {copied && (
                  <span className="absolute top-full mt-1 left-1/2 transform -translate-x-1/2 px-2 py-1 text-xs bg-black text-white rounded">
                    Copied!
                  </span>
                )}
              </button>
            </div>
            {/* Light switch */}
            <ThemeToggle />
          </div>
        </div>
      </div>
    </header>
  );
}
