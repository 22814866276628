import "../css/style.css";
import * as Sentry from "@sentry/react";
import {parse} from "cookie";
import { OpenAPI } from "./api";
import Home from "./pages/Home";
import { ThemeProvider } from './components/ThemeContext';
import Header from './components/Header';
import BgShapes from './components/BGShapes';
import VerticalLines from './components/VerticalLines';
import Footer from "./components/Footer";


OpenAPI.interceptors.request.use((request) => {
  const { csrftoken } = parse(document.cookie);
  if (request.headers && csrftoken) {
    request.headers["X-CSRFTOKEN"] = csrftoken;
  }
  return request;
});

const App = () => (
  <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
    <div className="font-inter antialiased bg-teal-100 text-gray-800 dark:bg-gray-900 dark:text-gray-200 tracking-tight">
      <ThemeProvider>
        <div className="relative flex flex-col min-h-screen overflow-hidden supports-[overflow:clip]:overflow-clip">
          <VerticalLines />
          <BgShapes />
          <Header />
          <main className="grow z-30">
            <Home />
          </main>
          <Footer />
          </div>
      </ThemeProvider>
    </div>
  </Sentry.ErrorBoundary>
);

export default App;
