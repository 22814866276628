import { useState, useEffect } from "react";
import { RestService } from "../api";
import { RestJoinWaitlistCreateData } from "../api";

// Import your avatar images
import Avatar01 from "../../assets/images/avatar-01.jpg";
import Avatar02 from "../../assets/images/avatar-02.jpg";
import Avatar03 from "../../assets/images/avatar-03.jpg";
import Avatar04 from "../../assets/images/avatar-04.jpg";
import Avatar05 from "../../assets/images/avatar-05.jpg";

export default function SubscribeForm() {
  const [email, setEmail] = useState("");
  const [statusMessage, setStatusMessage] = useState<string | null>(null);
  const [statusVariant, setStatusVariant] = useState<"success" | "danger" | null>(null);
  const [loading, setLoading] = useState(false);

  const handleJoinWaitlist = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setStatusMessage(null);

    try {
      await RestService.restJoinWaitlistCreate({
        requestBody: { email },
      } as RestJoinWaitlistCreateData);
      setStatusVariant("success");
      setStatusMessage("You have been added to the waitlist!");
      setEmail("");
    } catch (error) {
      console.error("Failed to join waitlist:", error);
      setStatusVariant("danger");
      setStatusMessage("Failed to join the waitlist. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Reset the button after 5 seconds when statusMessage changes
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (statusMessage) {
      timeoutId = setTimeout(() => {
        setStatusMessage(null);
        setStatusVariant(null);
      }, 5000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [statusMessage]);

  return (
    <>
      <div className="relative flex items-center justify-center gap-10 mb-11">
        <div className="w-full max-w-xs mx-auto shrink-0 relative">
        <form className="relative" onSubmit={handleJoinWaitlist}>
            {/* Border with dots in corners */}
            <div
              className="absolute -inset-3 bg-indigo-500/15 dark:bg-transparent dark:bg-gradient-to-b dark:from-gray-700/80 dark:to-gray-700/70 rounded-lg -z-10 before:absolute before:inset-y-0 before:left-0 before:w-[15px] before:bg-[length:15px_15px] before:[background-position:top_center,bottom_center] before:bg-no-repeat before:[background-image:radial-gradient(circle_at_center,theme(colors.indigo.500/.56)_1.5px,transparent_1.5px),radial-gradient(circle_at_center,theme(colors.indigo.500/.56)_1.5px,transparent_1.5px)] dark:before:[background-image:radial-gradient(circle_at_center,theme(colors.gray.600)_1.5px,transparent_1.5px),radial-gradient(circle_at_center,theme(colors.gray.600)_1.5px,transparent_1.5px)] after:absolute after:inset-y-0 after:right-0 after:w-[15px] after:bg-[length:15px_15px] after:[background-position:top_center,bottom_center] after:bg-no-repeat after:[background-image:radial-gradient(circle_at_center,theme(colors.indigo.500/.56)_1.5px,transparent_1.5px),radial-gradient(circle_at_center,theme(colors.indigo.500/.56)_1.5px,transparent_1.5px)] dark:after:[background-image:radial-gradient(circle_at_center,theme(colors.gray.600)_1.5px,transparent_1.5px),radial-gradient(circle_at_center,theme(colors.gray.600)_1.5px,transparent_1.5px)]"
              aria-hidden="true"
            />
            <div className="space-y-3">
              <div>
                <label className="sr-only" htmlFor="email">
                  Email
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 text-gray-500/70 dark:text-gray-400/70 pl-4 flex items-center pointer-events-none">
                    <svg
                      className="fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={14}
                    >
                      <path d="M14 0H2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm0 12H2V5.723l5.504 3.145a.998.998 0 0 0 .992 0L14 5.723V12Zm0-8.58L8 6.849 2 3.42V2h12v1.42Z" />
                    </svg>
                  </div>
                  <input
                    id="email"
                    className="form-input text-sm w-full pl-10 pr-4"
                    type="email"
                    placeholder="Your email..."
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className={`btn w-full ${
                    statusVariant === "success"
                      ? "bg-green-500 text-white hover:bg-green-600"
                      : statusVariant === "danger"
                      ? "bg-red-500 text-white hover:bg-red-600"
                      : "bg-gray-900 text-gray-100 hover:bg-gray-800 dark:text-gray-800 dark:bg-gray-100 dark:hover:bg-white"
                  }`}
                  disabled={loading}
                >
                  {loading
                    ? "Submitting..."
                    : statusMessage
                    ? statusMessage
                    : "Get Your FREE API Key - Join our Waitlist"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* Avatars and Text */}
      <div className="max-w-3xl mx-auto">
        <div className="text-center">
          {/* Avatars */}
          <ul className="flex justify-center -space-x-2 mb-4">
            <li>
              <img
                className="rounded-full"
                src={Avatar01}
                width={32}
                height={32}
                alt="Avatar 01"
              />
            </li>
            <li>
              <img
                className="rounded-full"
                src={Avatar02}
                width={32}
                height={32}
                alt="Avatar 02"
              />
            </li>
            <li>
              <img
                className="rounded-full"
                src={Avatar03}
                width={32}
                height={32}
                alt="Avatar 03"
              />
            </li>
            <li>
              <img
                className="rounded-full"
                src={Avatar04}
                width={32}
                height={32}
                alt="Avatar 04"
              />
            </li>
            <li>
              <img
                className="rounded-full"
                src={Avatar05}
                width={32}
                height={32}
                alt="Avatar 05"
              />
            </li>
          </ul>
          <p className="text-sm text-gray-500">
            Join the  {" "}
            <span className="text-gray-700 dark:text-gray-200 font-medium">
              1,500+
            </span>{" "}
            AI Engineers that have already signed up.
          </p>
        </div>
      </div>
    </>
  );
}
