// src/pages/Home.jsx

import PageHeader from "../components/PageHeader";
import SubscribeForm from "../components/SubscribeForm";

const Home = () => {
  const features = [
    "Share your prompts with ease.",
    "Never re-write a prompt again.",
    "Load your prompt in milliseconds.",
    "Search our DB of public prompts.",
    "Store your prompts forever.",
  ];

  return (
    <section>
      <div className="pt-32 pb-12 md:pt-44 md:pb-20">
        <div className="px-4 sm:px-6 text-center">
          {/* Page Header */}
          <PageHeader
            className="mb-8"
            title="The API for Professional Prompt Engineering"
            description="Build smarter, faster agents with ThoughtBase — the API that simplifies prompt management and retrieval for AI Agents. Designed for AI Engineers, by AI Engineers."
          >
            Discover <span className="text-gray-300 mx-1">·</span> Create{" "}
            <span className="text-gray-300 mx-1">·</span> Store{" "}
            <span className="text-gray-300 mx-1">·</span> Share
          </PageHeader>

          {/* Features Section */}
          <div className="max-w-3xl mx-auto mb-10">
            <ul className="grid grid-cols-1 md:grid-cols-2 gap-y-6 gap-x-8">
              {features.map((feature, index) => (
                <li key={index} className="flex items-center justify-center">
                  <div className="flex items-center">
                    <svg
                      className="w-6 h-6 text-teal-500 flex-shrink-0 mr-3"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                    <span className="text-gray-700 dark:text-gray-300">
                      {feature}
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          {/* Subscribe Form */}
          <SubscribeForm />
        </div>
      </div>
    </section>
  );
};

export default Home;
